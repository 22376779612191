import {
    format as dateFnsFormat,
    formatDistance as dateFnsFormatDistance
} from 'date-fns';
import {hu} from 'date-fns/locale';

export default class Formatter {
    constructor() {
        this.config = {
            formats: {
                date: 'yyyy. MMMM d. HH:mm:ss'
            },
            locales: {hu},
            binary: false
        };
    }

    install(app, options) {
        const exposed = {
            filesize: this.filesize.bind(this),
            date: this.date.bind(this),
            fromNow: this.fromNow.bind(this),
            groupNumbers: this.groupNumbers.bind(this)
        };

        /**
         * Example:
         * this.$root.$formatter
         */
        app.config.globalProperties.$formatter = exposed;

        /**
         * Example:
         * import { inject } from 'vue';
         *
         * const Formatter = inject('Formatter')
         */
        app.provide('Formatter', exposed);
    }

    _isEmpty(value) {
        return value === 0 || !value;
    }

    date(date, format) {
        if(this._isEmpty(date)) {
            return;
        }

        // TODO: Bug: Datefns is not handling the timezones at all. This effect (bug) won't be visible in the same timezone where the database is.
        return dateFnsFormat(new Date(date), format ? format : this.config.formats.date, {
            weekStartsOn: 1, // Monday
            locale: this.config.locales.hu
        });
    }

    fromNow(date) {
        if(this._isEmpty(date)) {
            return;
        }

        return dateFnsFormatDistance(new Date(date), new Date(), { locale: this.config.locales.hu, addSuffix: true });
    }

    /**
     * @param {integer} bytes
     * @param {integer} decimals Displayed precision
     * @param {boolean} binary Binary or decimal magnitude
     * @returns {string}
     */
    filesize(bytes, decimals, binary) {
        // Keep it without type comparison or null and similar "bytes" will not enter this early return!
        if(bytes == 0) {
            return '0 b';
        }

        let kilo = binary || this.config.binary ? 1024 : 1000,
            decimalsLength = decimals || 2,
            sizes = binary || this.config.binary ?
                ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'] :
                ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            index = Math.floor(Math.log(bytes) / Math.log(kilo));

        return parseFloat((bytes / Math.pow(kilo, index)).toFixed(decimalsLength)) + ' ' + sizes[index];
    }

    /**
     * Formats numbers into strings and separates the thousand groups by the separator.
     *
     * @param number
     * @param size
     * @param separator
     * @param decimalSeparator
     * @returns {string}
     */
    groupNumbers(number, size = 3, separator = ' ', decimalSeparator = '.') {
        let strings = number.toString().split(decimalSeparator);

        _.forEach(strings, function(value, index) {
            strings[index] = value.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
        });

        return strings.join(decimalSeparator);
    }
};
